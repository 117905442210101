import React from "react"
import { ThemeProvider } from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Navigation from "sections/Navigation"
import BlogSinglePage from "sections/Blog-single"
import Footer from "sections/Footer"

import theme from "assets/theme/theme"
import GlobalStyle from "assets/theme"

import ReactGA from 'react-ga';

// const TRACKING_ID = "G-5L3PPX35LR"; // YOUR_OWN_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);
ReactGA.pageview('/');
ReactGA.pageview('/blog-single');

const BlogSingle = () => (
  <ThemeProvider theme={theme}>
    <Layout>
      <GlobalStyle />
      <SEO title="Blog" />
      <Navigation />
      <BlogSinglePage />
      {/* <Footer /> */}
    </Layout>
  </ThemeProvider>
)
export default BlogSingle
