import React from "react"
import { Link } from "gatsby"

import {
  TiSocialFacebook,
  TiSocialTwitter,
  TiSocialPinterest,
  TiSocialLinkedin,
} from "react-icons/ti";
import { IoIosArrowRoundForward } from "react-icons/io"

import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Text from "reusecore/Text"
import Heading from "reusecore/Heading"
import Image from "reusecore/Image"
import { List, ListItem } from "reusecore/List"
import Button from "reusecore/Button"
import PageHeader from "../../components/PageHeader"
import Sidebar from "../../components/Blog-sidebar"

import BlogThumb from "assets/images/blog/post/blog-post-5.png"
import image1 from 'assets/images/blog/post/blog-post-1.1.png';
import image2 from 'assets/images/blog/post/blog-post-1.2.png';
import image3 from 'assets/images/blog/post/blog-post-1.3.png';
import image4 from 'assets/images/blog/post/blog-post-1.4.png';

import AuthorThumb2 from "assets/images/testimonial/testimonial-thumb-2.jpg"
import AuthorThumb3 from "assets/images/testimonial/testimonial-thumb-3.jpg"
import AuthorThumb4 from "assets/images/testimonial/testimonial-thumb-4.jpg"
import AuthorThumb5 from "assets/images/testimonial/testimonial-thumb-5.jpg"

import BlogPageWrapper from "./blogSingle.style"

const BlogSinglePage = () => {
  return (
    <BlogPageWrapper>
      {/* <PageHeader title="Buy Africoin (AFC)!" /> */}
      <Box className="single-post-wrapper">
        <Container>
          <Row>
            <Col className="xs-12 lg-8">
              <Row>
                <Col className="xs-12">
                  <Box className="single-post-block">
                    <Box className="post-thumb-block">
                      {/* <Image src={BlogThumb} alt="cryptik blog page" />
                      <Box className="post-meta">
                        <Text as="span">
                          <Text as="em"> 15 </Text> DEC
                        </Text>
                      </Box> */}
                    </Box>
                    <Box className="post-content-block">
                      <Heading className="post-title">
                        How to buy your first Africoin (AFC)!
                      </Heading>
                      <Text className="entry-one">
                        <br/>
                        Africoin is in Alpha Phase, but you can already 'buy' AFC on the Testnet for free!<br/>
                        <br/>
                        Follow the steps below (duration: ~15 minutes): <br/>
                        <br/>
                        1. Download and install the Metamask Wallet. Here you can
                        download Metamask:{' '}
                        <Link to="https://metamask.io/" target="_blank" >
                          https://metamask.io/
                        </Link><br/>
                        <br/>
                        2. Click on 'Ethereum Mainnet', click on 'Show/hide test networks', then select 'Goerli Test Network' (see image)<br/>
                        <br/>
                        <Image src={image1} alt="image 1.1" style={{maxWidth: 300, paddingLeft: 50}}/><br/>
                        3. Surf to Uniswap:{' '}
                        <Link to="https://app.uniswap.org/#/swap" target="_blank" >
                          https://app.uniswap.org/#/swap
                        </Link><br/>
                        <br/>
                        4. Click on 'ETH', then search and select 'USDC' (if you do not have USDC or ETH in your Metamask wallet then proceed to #10 in this list<br/>
                        <br/>
                        5. Click on 'Select a token', and paste the following address:<br/>
                        0x78B27611eD9950fFe0DE7410EA96535196c9010d<br/>
                        Then select 'AFC' <br/>
                        <br/>
                        6. Enter the amount of 'USDC' you would like to swap for 'AFC' and click on 'Swap' (see image)<br/>
                        <br/>
                        <Image src={image3} alt="image 1.3" style={{maxWidth: 300, paddingLeft: 50}}/><br/>
                        7. Approve Uniswap accessing USDC in the first Metamask transaction and 'Confirm' in the second transaction<br/>
                        <br/>
                        Congratulations, you are now the owner of your first Africoins!<br/>
                        <br/>
                        8. To make the AFC appear in your Metamask Wallet: in the 'Assets' tab, scroll down and click on 'Import tokens'<br/>
                        <br/>
                        9. Paste the following address in the 'Token Contract Address'<br/>
                        0x78B27611eD9950fFe0DE7410EA96535196c9010d<br/>
                        Then click on 'Add custom token' and 'Import token'<br/>
                        <br/>
                        Your AFC should now appear in Metamask!<br/>
                        <br/>
                        10. (Continuation of #4 in this list) Surf to Compound:{' '}
                        <Link to="https://app.compound.finance/" target="_blank" >
                          https://app.compound.finance/
                        </Link><br/>
                        <br/>
                        11. Click on 'USD Coin' in the 'Supply Markets' column<br/>
                        <br/>
                        12. Click on the 'Withdraw' tab and then on 'FAUCET' located in the far bottom of the pop-up screen (see image)<br/>
                        <br/>
                        <Image src={image2} alt="image 1.2" style={{maxWidth: 300, paddingLeft: 50}}/><br/>
                        13. 'Confirm' the Metamask transaction, wait for the 100 USDC to arrive. Continue to #14 if you do not have ETH in your wallet, but if you do go back to #4 in this list<br/>
                        <br/>
                        14. Surf to Chainlink Faucets:{' '}
                        <Link to="https://faucets.chain.link/rinkeby" target="_blank" >
                          https://faucets.chain.link/rinkeby
                        </Link><br/>
                        <br/>
                        15. Insert your Metamask address in the 'Testnet account address' box and click 'Send request' (see image). Go back to #4 in this list to get your Africoin<br/>
                        <br/>
                        <Image src={image4} alt="image 1.4" style={{maxWidth: 500, paddingLeft: 50}}/><br/>
                      </Text>
                      {/* <Text className="entry-two">
                        Coracoes costumes grandeza com observou horrivel mas.
                        Amor tive fara de dado esse em. Ve es couberam oh
                        garrafal mListheres. Dourados duzentos voz lustroso diz
                        discutir ahi. Luzindo no do tremLista na so fallava.
                        Evitava tropheu curiosa ou agitado os acceite si
                        assiste. Voz veio veja tez digo cres.
                      </Text>
                      <blockquote>
                        You never change things by fighting the existing
                        reality.To change something, build a new model.
                      </blockquote>
                      <Text className="entry-three">
                        Ao corrida ar queriam reparae do imposta acoitar do.
                        Qualidades intimativa aferventar ira acompanhar mau
                        capitListada enfraquece. Em apavorar ficarmos cantante
                        se ia blasonou eu comprido.
                      </Text>

                      <Text className="entry-four">
                        Coracoes costumes grandeza com observou horrivel mas.
                        Amor tive fara de dado esse em. Ve es couberam oh
                        garrafal mListheres. Dourados duzentos voz lustroso diz
                        discutir ahi. Luzindo no do tremLista na so fallava.
                        Evitava tropheu curiosa ou agitado os acceite si
                        assiste. Voz veio veja tez digo cres.
                      </Text> */}
                    </Box>
                    <Box className="post-info-block">
                      {/* <Box className="tags">
                        <Link to="#">#crypto</Link>
                        <Link to="#">#Landing</Link>
                        <Link to="#">#Bitcoin</Link>
                      </Box>
                      <Box className="share">
                        <Link to="#">
                          <TiSocialFacebook />
                        </Link>
                        <Link to="#">
                          <TiSocialTwitter />
                        </Link>
                        <Link to="#">
                          <TiSocialPinterest />
                        </Link>
                        <Link to="#">
                          <TiSocialLinkedin />
                        </Link>
                      </Box> */}
                    </Box>

                    {/* <Box className="post-comment-wrapper">
                      <Heading as="h2" className="block-title">
                        5 Comments
                      </Heading>
                      <List>
                        <ListItem>
                          <Box className="post-comment-block">
                            <Box className="author-avatar">
                              <Image
                                src={AuthorThumb2}
                                alt="cryptik comments author"
                              />
                            </Box>
                            <Box className="comment-body">
                              <Box className="comment-body-inner">
                                <Heading as="h3">
                                  Amrita<Text as="span">Dec, 05, 2020</Text>
                                </Heading>
                                <Box className="replay">
                                  <Link to="#">
                                    {" "}
                                    Replay <IoIosArrowRoundForward />
                                  </Link>
                                </Box>
                              </Box>
                              <Box className="comment-content">
                                <Text>
                                  Finalmente vol sol justamente crematorio vil
                                  nos. Bem com sido esse case alem aos.Amor tive
                                  fara de dado esse em.
                                </Text>
                              </Box>
                            </Box>
                          </Box>
                          <List>
                            <ListItem>
                              <Box className="post-comment-block">
                                <Box className="author-avatar">
                                  <Image
                                    src={AuthorThumb3}
                                    alt="cryptik comments author"
                                  />
                                </Box>
                                <Box className="comment-body">
                                  <Box className="comment-body-inner">
                                    <Heading as="h3">
                                      {" "}
                                      jacqueline{" "}
                                      <Text as="span">Dec, 05, 2020</Text>{" "}
                                    </Heading>
                                    <Box className="replay">
                                      <Link to="#">
                                        {" "}
                                        Replay <IoIosArrowRoundForward />
                                      </Link>
                                    </Box>
                                  </Box>
                                  <Box className="comment-content">
                                    <Text>
                                      Finalmente vol sol justamente crematorio
                                      vil nos. Bem com sido esse case alem
                                      aos.Amor tive fara de dado esse em.
                                    </Text>
                                  </Box>
                                </Box>
                              </Box>
                            </ListItem>
                            <ListItem>
                              <Box className="post-comment-block">
                                <Box className="author-avatar">
                                  <Image
                                    src={AuthorThumb4}
                                    alt="cryptik comments author"
                                  />
                                </Box>
                                <Box className="comment-body">
                                  <Box className="comment-body-inner">
                                    <Heading as="h3">
                                      {" "}
                                      Jennifer{" "}
                                      <Text as="span">Dec, 05, 2020</Text>{" "}
                                    </Heading>
                                    <Box className="replay">
                                      <Link to="#">
                                        {" "}
                                        Replay <IoIosArrowRoundForward />
                                      </Link>
                                    </Box>
                                  </Box>
                                  <Box className="comment-content">
                                    <Text>
                                      Finalmente vol sol justamente crematorio
                                      vil nos. Bem com sido esse case alem
                                      aos.Amor tive fara de dado esse em.
                                    </Text>
                                  </Box>
                                </Box>
                              </Box>
                            </ListItem>
                          </List>
                        </ListItem>
                        <ListItem>
                          <Box className="post-comment-block">
                            <Box className="author-avatar">
                              <Image
                                src={AuthorThumb5}
                                alt="cryptik comments author"
                              />
                            </Box>
                            <Box className="comment-body">
                              <Box className="comment-body-inner">
                                <Heading as="h3">
                                  {" "}
                                  William <Text as="span">
                                    Aug, 05, 2020
                                  </Text>{" "}
                                </Heading>
                                <Box className="replay">
                                  <Link to="#">
                                    {" "}
                                    Replay <IoIosArrowRoundForward />
                                  </Link>
                                </Box>
                              </Box>
                              <Box className="comment-content">
                                <Text>
                                  Finalmente vol sol justamente crematorio vil
                                  nos. Bem com sido esse case alem aos.Amor tive
                                  fara de dado esse em.
                                </Text>
                              </Box>
                            </Box>
                          </Box>
                        </ListItem>
                      </List>
                    </Box>
                    <Box className="comment-box-wrapper">
                      <Heading as="h2" className="block-title">
                        Leave a Comment
                      </Heading>
                      <Text>
                        Sing in to post your comment or singup if you dont have
                        any account.
                      </Text>
                      <form>
                        <Box className="form-inline">
                          <input type="text" placeholder="Name*" />
                          <input type="email" placeholder="Email*" />
                        </Box>
                        <Box className="form-group">
                          <input type="text" placeholder="Website(Optional)" />
                        </Box>
                        <textarea
                          rows="10"
                          cols="50"
                          placeholder="Comment"
                        ></textarea>
                        <Button>Post Comment</Button>
                      </form>
                    </Box> */}
                  </Box>
                </Col>
              </Row>
            </Col>
            {/* <Col className="sm-12 lg-4">
              <Sidebar />
            </Col> */}
          </Row>
        </Container>
      </Box>
    </BlogPageWrapper>
  )
}

export default BlogSinglePage
